import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLoading } from "../../Context/LoadingProvider";
import { useUser } from "../../Context/UserProvider";
import OrderListIcon from "../../Assets/gfx/icons/nav/order-list.svg";
import DashboardIcon from "../../Assets/gfx/icons/dashboardIcon.png";
import { useTranslation } from "react-i18next";
import { patrollerService } from '../../Api/Services/PatrollerService';
import { Shift } from "../../Api/Services/PatrollerService";
import { CirclePauseIcon } from "../../Components/Icons/CirclePauseIcon";
import { CirclePlayIcon } from "../../Components/Icons/CirclePlayIcon";


interface FunctionsMenuProps {}

const FunctionsMenu: React.FunctionComponent<FunctionsMenuProps> = () => {
  const navigate = useNavigate();
  const { startLoading, finnishLoading } = useLoading();
  const { logout } = useUser();
  const { t } = useTranslation();

  const [shiftData, setShiftData] = useState<Shift | null>(null);
  const [elapsedSeconds, setElapsedSeconds] = useState<number>(0);

  const fetchShiftData = async () => {
    try {
      const data = await patrollerService.getSift();
      setShiftData(data);
      setElapsedSeconds(parseInt(data.totalSeconds));
    } catch (error) {
      console.error("Hiba történt a Shift adatok lekérése során:", error);
    }
  };

  useEffect(() => {
    fetchShiftData();
  }, []);

  const handleClockIn = async () => {
    try {
      const data = await patrollerService.getClockInData();
      console.log("Clock-in successful:", data);
      await fetchShiftData();
    } catch (error) {
      console.error("Hiba történt a Clock In során:", error);
    }
  };

  const handleClockOut = async () => {
    try {
      const data = await patrollerService.getClockOutData();
      console.log("Clock-out successful:", data);
      await fetchShiftData();
    } catch (error) {
      console.error("Hiba történt a Clock Out során:", error);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (shiftData?.inShift) {
      timer = setInterval(() => {
        setElapsedSeconds((prev) => prev + 1);
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [shiftData?.inShift]);

  const formatTime = (totalSeconds: number) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleLogout = () => {
    startLoading("logout");
    logout()
        .then(() => {
          navigate("/");
        })
        .finally(() => {
          finnishLoading("logout");
        });
  };

  return (
      <div className="view-container background-3">
        <div className="view-content">
          <div className="flex justify-center mt-12 mb-3">
            {shiftData?.inShift ? (
                <button
                    type="button"
                    className="shift-button shift-button--clock-out"
                    onClick={handleClockOut}
                >
                  <div className="shift-button__icon">
                    <CirclePauseIcon/>
                  </div>
                  {t("Clock Out")}
                </button>
            ) : (
                <button
                    type="button"
                    className="shift-button shift-button--clock-in"
                    onClick={handleClockIn}
                >
                  <div className="shift-button__icon">
                    <CirclePlayIcon/>
                  </div>
                  {t("Clock In")}
                </button>
            )}

          </div>

          <div className="text-center mt-12 mb-12 text-white">
            today shift: <strong>{formatTime(elapsedSeconds)}</strong>
          </div>

            <div className="limited-width-content">
              <div className="card-list">
                <Link to={"/rider/search"} className={"nav-card"}>
                  <img src={OrderListIcon} className="nav-card__icon" alt="Rider search"/>
                  <div className="nav-card__title">{t("Rider Search")}</div>
                </Link>
                <Link to={"/dashboard"} className={"nav-card"}>
                  <img src={DashboardIcon} className="nav-card__icon" alt="Rider search"/>
                  <div className="nav-card__title">{t("Dashboard")}</div>
                </Link>

                <div className="text-center mt-12">
                  <button
                      type="button"
                      className="button button--orange button--small"
                      onClick={handleLogout}
                  >
                    {t("Logout")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        );
        };

        export default FunctionsMenu;
